import PartnerLogo from 'components/common/partner-logo';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import React, { FC } from 'react';

import styles from '../stations-map.module.scss';
import { StationData } from '../types';

interface Props {
  station: StationData;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationWindow: FC<Props> = (props) => {
  const { t } = useTranslation();
  const setFilter = () => {
    props.setStationFilter([props.station.id]);
  };
  return (
    <div className={styles.stationDetails}>
      <span className={styles.stationDetails__name}>{props.station.name}</span>
      <PartnerLogo name={props.station.partner} label="partner" />

      <Button onClick={setFilter}>{t('0nc_choose_this_station')}</Button>
    </div>
  );
};

StationWindow.defaultProps = {
  setStationFilter: () => null
};

export default StationWindow;
