import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { ArrayFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import { StationType } from 'src/stations/types';
import UsedFilterWrapper from '../used-filter-wrapper';

type Props = {
  filter: ArrayFilter<StationType>;
  labels?: any;
};

const StationUsedFilter: FC<Props> = ({ filter, labels }) => {
  const { t } = useTranslation();
  const { updateFilter } = useOffersContext();
  if (filter.value.length === 0) return null;

  const removeStation = (station: StationType) => {
    updateFilter({
      type: 'station',
      value: filter.value.filter((item) => item !== station)
    });
  };

  return (
    <>
      {filter.value.map((station) => (
        <UsedFilterWrapper key={station} onClear={() => removeStation(station)}>
          {t(labels[station].textId)}
        </UsedFilterWrapper>
      ))}
    </>
  );
};

StationUsedFilter.defaultProps = {
  labels: undefined
};

export default StationUsedFilter;
