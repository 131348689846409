import React, { FC } from 'react';
import { get } from 'lodash';
import Supercluster from 'supercluster';
import { reverseCoordinates, stationDataGeoJSON } from 'src/utils/data-conversion';
import { Feature } from 'geojson';
import { PigeonProps, StationClusterData, StationData } from '../types';
import StationMarker from './station-marker';
import StationClusterMarker from './station-cluster-marker';

interface Props extends PigeonProps {
  stationsData?: Array<StationData>;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationClusters: FC<Props> = (props) => {
  const { bounds } = props.mapState;

  const cluster = new Supercluster({
    radius: 120,
    maxZoom: 16,
    map: (station: StationData) => ({
      clusterData: false,
      partners: [station.partner],
      carsQty: station.cars.length,
      minPrice:
        station.cars.length > 0
          ? station.cars.reduce((min, car) => (car.price < min.price ? car : min)).price
          : 0,
      maxPrice:
        station.cars.length > 0
          ? station.cars.reduce((max, car) => (car.price > max.price ? car : max)).price
          : 0,
      stations: [station]
    }),
    reduce: (accumulated: StationClusterData, current: StationClusterData) => {
      accumulated.carsQty += current.carsQty;

      if (current.minPrice < accumulated.minPrice) {
        accumulated.minPrice = current.minPrice;
      }
      if (current.maxPrice > accumulated.maxPrice) {
        accumulated.maxPrice = current.maxPrice;
      }
      accumulated.partners = accumulated.partners
        .concat(current.partners)
        .filter((elem, index, self) => index === self.indexOf(elem));
      accumulated.stations = accumulated.stations.concat(current.stations);

      return accumulated;
    }
  });

  const stationMarkers: Array<Feature> = [];
  const clusterMarkers: Array<Feature> = [];

  cluster.load(
    props.stationsData
      .filter((station) => station.cars.length > 0)
      .map((station) => stationDataGeoJSON(station))
  );

  const data: Array<any> = cluster.getClusters(
    [bounds.sw[1], bounds.sw[0], bounds.ne[1], bounds.ne[0]],
    Math.floor(props.mapState.zoom)
  );

  data.forEach((item) => {
    if (get(item, 'properties.cluster', false)) {
      clusterMarkers.push(item);
    } else {
      stationMarkers.push(item);
    }
  });

  return (
    <>
      {stationMarkers
        .map((geo) => {
          geo.geometry['coordinates'] = reverseCoordinates(geo.geometry['coordinates']);
          return geo;
        })
        .map((geo) => (
          <StationMarker
            key={geo.properties.id}
            anchor={geo.geometry['coordinates']}
            top={props.latLngToPixel(geo.geometry['coordinates'])[1]}
            left={props.latLngToPixel(geo.geometry['coordinates'])[0]}
            data={geo.properties as StationData}
            setStationFilter={props.setStationFilter}
          />
        ))}

      {clusterMarkers
        .map((geo) => {
          geo.geometry['coordinates'] = reverseCoordinates(geo.geometry['coordinates']);
          return geo;
        })
        .map((geo: Feature) => (
          <StationClusterMarker
            data={geo.properties as StationClusterData}
            key={geo.id}
            anchor={geo.geometry['coordinates']}
            top={props.latLngToPixel(geo.geometry['coordinates'])[1]}
            left={props.latLngToPixel(geo.geometry['coordinates'])[0]}
            setStationFilter={props.setStationFilter}
          />
        ))}
    </>
  );
};

StationClusters.defaultProps = {
  stationsData: [],
  setStationFilter: () => null
};

export default StationClusters;
