import React, { FC, ReactNode } from 'react';
import Button from 'components/forms/button';
import CarIcon from 'components/common/car-icon';
import styles from './filters.module.scss';

type Props = {
  onClear?: () => void;
  children: ReactNode;
};

const UsedFilterWrapper: FC<Props> = (props) => (
  <li className={styles.usedFilters__item}>
    <Button variant="secondary" onClick={props.onClear}>
      {props.children}
      <CarIcon icon="cross" size="9px" />
    </Button>
  </li>
);

UsedFilterWrapper.defaultProps = {
  onClear: () => null
};

export default UsedFilterWrapper;
