import React, { FC, ReactNode, useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import useCarsApi from 'providers/cars-api/use-cars-api';
import CarIcon from 'components/common/car-icon';
import { CarOffer } from 'src/cars/types';

import styles from './compare.module.scss';

interface Props {
  inUse: boolean;
  offer: CarOffer;
  children?: ReactNode;
  tabIndex?: number;
}

const CompareWrapperBtn: FC<Props> = (props) => {
  const { compare, addToCompare, removeFromCompare } = useCarsApi();
  const [checked, setChecked] = useState(false);

  const toggleCompare = () => {
    if (!checked) {
      addToCompare(props.offer);
    } else {
      removeFromCompare(props.offer.offerId);
    }
    setChecked(!checked);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      toggleCompare();
    }
  };

  useEffect(() => {
    const newState = compare.some((el) => el.offerId === props.offer.offerId);
    if (newState !== checked) {
      setChecked(newState);
    }
  }, [checked, compare]);

  if (!props.inUse) return <>{props.children}</>;

  return (
    <div
      tabIndex={props.tabIndex}
      role="button"
      className={styles.compareBar__wrapper_container}
      onClick={toggleCompare}
      onKeyDown={handleKeyDown}
    >
      {props.children}
      <div
        className={classNames(
          styles.compareBar__wrapper_content,
          checked ? styles.compareBar__inCompare : undefined
        )}
      >
        {checked && <CarIcon icon="check" size="60px" />}
        {!checked && <CarIcon icon="add" size="60px" />}
      </div>
    </div>
  );
};

CompareWrapperBtn.defaultProps = {
  children: null,
  tabIndex: 0
};

export default memo(CompareWrapperBtn);
