import React from 'react';
import classNames from 'classnames';

import useTranslation from 'providers/translations/use-translations';
import { getLocationDetails, getLocationIcon, getLocationlabel } from './utils';

import styles from './suggester.module.scss';
import { SuggesterItem } from './types';

interface SuggesterRowProps {
  i: number;
  index: number;
  location: SuggesterItem;
  level?: number;
  inactive?: boolean;
  onClick?(location: SuggesterItem, i: number): void;
  onMouseEnter?(i: number): void;
}

const SuggesterRow: React.FC<SuggesterRowProps> = (props) => {
  const { t } = useTranslation();
  const { i, index, location, level, inactive, onClick, onMouseEnter } = props;

  return (
    <li>
      <button
        type="button"
        className={classNames(styles.locationBtn, [
          `level-${level}`,
          {
            [styles['locationBtn--highlight']]: index === i,
            [styles['locationBtn--inactive']]: inactive,
            [styles['locationBtn--level-1']]: level === 1
          }
        ])}
        onClick={() => (!inactive ? onClick(location, i) : null)}
        onMouseEnter={() => (!inactive ? onMouseEnter(i) : null)}
      >
        <div className={styles.locationIconContainer}>{getLocationIcon(location.locationType)}</div>

        <div>
          <span
            className={classNames(styles.locationName, {
              [styles['locationName--hover']]: index === i
            })}
          >
            {getLocationlabel(location)}
          </span>
          <span className={styles.locationDetails}>
            <span>{getLocationDetails(location)}</span>
            {location.locationType === 'AIRP' && <span>{` (${t('airport')})`}</span>}
            {location.locationType === 'RSTN' && <span>{` (${t('train_station')})`}</span>}
          </span>
        </div>
      </button>
    </li>
  );
};

SuggesterRow.defaultProps = {
  level: 1,
  inactive: false,
  onClick: () => null,
  onMouseEnter: () => null
};

export default SuggesterRow;
