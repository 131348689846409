import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './common.module.scss';

type Props = {
  progress: number;
  className?: string;
};

const ProgressBar: FC<Props> = ({ progress, className }) => (
  <div className={classNames(styles.progressBar, className)}>
    <div className={styles.progressBar__indicator} style={{ width: `${progress}%` }} />
  </div>
);

ProgressBar.defaultProps = {
  className: undefined
};

export default ProgressBar;
