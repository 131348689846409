import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useTranslation from 'providers/translations/use-translations';
import Checkbox from 'components/forms/checkbox';
import { sameArrays } from 'src/utils/array';
import { ArrayFilter } from 'src/filters/types';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

type Props = {
  label?: string;
  disabled?: boolean;
};

const PartnerFilterBox: FC<Props> = ({ label, disabled }) => {
  const { t } = useTranslation();
  const { filters, filtersRange, updateFilter, pricesStartsFrom } = useOffersContext();
  const { isMobile } = useScreenDetect();
  const [available, setAvailable] = useState<Array<string>>([]);
  const { general } = useCarsApi();
  const [selected, setSelected] = useState<Array<string>>([]);

  const selectionChange = (partner: string, checked: boolean) => {
    if (checked) {
      selected.push(partner);
    } else {
      selected.splice(selected.indexOf(partner), 1);
    }
    setSelected(clone(selected));
    updateFilter({
      type: 'partner',
      value: clone(selected)
    });
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'partner') as ArrayFilter;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);

  useEffect(() => {
    if (filtersRange) {
      setAvailable(filtersRange.partners ? clone(filtersRange.partners.sort()) : []);
    } else {
      setAvailable([]);
    }
  }, [filtersRange]);

  if (available.length < 2) return null;

  return (
    <FilterBox title={label} collapsable collapsed>
      {available.map((partner) => (
        <Checkbox
          key={partner}
          reverse={isMobile}
          fullWidth={isMobile}
          value={selected.indexOf(partner) !== -1}
          onChange={(checked) => selectionChange(partner, checked)}
          label={t(`partner_${partner}`, partner)}
          disabled={
            disabled || !pricesStartsFrom['partner'] || !pricesStartsFrom['partner'][partner]
          }
        >
          {!disabled &&
            pricesStartsFrom['partner'] &&
            pricesStartsFrom['partner'][partner] &&
            pricesStartsFrom['partner'][partner].price && (
              <span className={styles.price}>
                {t('price_from')}
                <Price
                  round
                  className={styles.offerPrice}
                  price={pricesStartsFrom['partner'][partner].price}
                  currency={general.currency}
                />
              </span>
            )}
        </Checkbox>
      ))}
    </FilterBox>
  );
};

PartnerFilterBox.defaultProps = {
  label: undefined,
  disabled: false
};

export default PartnerFilterBox;
