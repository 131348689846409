import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
import Checkbox from 'components/forms/checkbox';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import { sameArrays } from 'src/utils/array';
import { CarClass } from 'types/car-class.type';
import { ArrayFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

export const CLASS_LIST: Array<CarClass> = [
  'mini',
  'economy',
  'compact',
  'intermediate',
  'premium',
  'luxury',
  'commercial'
];

type Props = {
  label?: string;
  disabled?: boolean;
};

const CarClassFilterBox: FC<Props> = ({ label, disabled }) => {
  const { t } = useTranslation();
  const { filters, updateFilter, pricesStartsFrom } = useOffersContext();
  const { isMobile } = useScreenDetect();
  const { general } = useCarsApi();

  const [selected, setSelected] = useState<Array<CarClass>>([]);

  const selectionChange = (carClass: CarClass, checked: boolean) => {
    if (checked) {
      selected.push(carClass);
    } else {
      selected.splice(selected.indexOf(carClass), 1);
    }
    setSelected(clone(selected));

    updateFilter({
      type: 'class',
      value: clone(selected)
    });
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'class') as ArrayFilter<CarClass>;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);
  return (
    <FilterBox title={label}>
      {CLASS_LIST.map((type) => (
        <Checkbox
          reverse={isMobile}
          fullWidth={isMobile}
          key={type}
          value={selected.indexOf(type) !== -1}
          onChange={(checked) => selectionChange(type, checked)}
          label={t(type)}
          disabled={disabled || !pricesStartsFrom['class'] || !pricesStartsFrom['class'][type]}
        >
          {!disabled &&
            pricesStartsFrom['class'] &&
            pricesStartsFrom['class'][type] &&
            pricesStartsFrom['class'][type].price && (
              <span className={styles.price}>
                {t('price_from')}
                <Price
                  round
                  className={styles.offerPrice}
                  price={pricesStartsFrom['class'][type].price}
                  currency={general.currency}
                />
              </span>
            )}
        </Checkbox>
      ))}
    </FilterBox>
  );
};

CarClassFilterBox.defaultProps = {
  label: undefined,
  disabled: false
};

export default CarClassFilterBox;
