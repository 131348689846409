import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';

import styles from './stations-map.module.scss';

type Props = {
  bottomOffset?: number;
  leftOffset?: number;
  anchorX?: number;
  anchorY?: number;
  children: ReactNode;
  onOverEvent?: (over: boolean) => void;
};

const WindowWrapper: FC<Props> = (props) => {
  const ref = useRef();
  const [bottomOffset, setBottomOffset] = useState(0);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    setLeftOffset(-Math.round(get(ref, 'current.offsetWidth', 0) / 2) + props.leftOffset);
    const upperOverflow = props.bottomOffset - props.anchorY + get(ref, 'current.offsetHeight', 0);
    setBottomOffset(props.bottomOffset - (upperOverflow > 0 ? upperOverflow : 0));
  }, [ref]);

  return (
    <div
      ref={ref}
      className={styles.markerWindow}
      style={{ left: `${leftOffset}px`, bottom: `${bottomOffset}px` }}
      onMouseEnter={() => {
        props.onOverEvent(true);
      }}
      onMouseLeave={() => {
        props.onOverEvent(false);
      }}
    >
      {props.children}
    </div>
  );
};

WindowWrapper.defaultProps = {
  bottomOffset: 0,
  leftOffset: 0,
  anchorX: 0,
  anchorY: 0,
  onOverEvent: () => null
};

export default WindowWrapper;
