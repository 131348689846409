import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';
import Button from 'components/forms/button';
import useOffersContext from 'providers/offers/use-offers-context';

import CarIcon from 'components/common/car-icon';
import styles from './block-message.module.scss';

export type MessageType = '' | 'noResults' | 'filteredOut';

type MessageTemplate = {
  id: string | number;
  title: string;
  message: ReactNode;
  buttons: Array<string>;
};

interface Props {
  type?: MessageType;
  className?: string;
}

const BlockMessage: FC<Props> = ({ type, className }) => {
  const { clearFilters } = useOffersContext();
  const { t } = useTranslation('common');

  if (!type) return null;

  const messages: Array<MessageTemplate> = [
    {
      id: 'noResults',
      title: t('offer_not_found'),
      message: <>{t('0nc_no_car_offers_for_params')}</>,
      buttons: []
    },
    {
      id: 'filteredOut',
      title: t('notification_all_offers_filtered_out_title'),
      message: <>{t('notification_all_offers_filtered_out_description')}</>,
      buttons: ['clearFilters']
    },
    {
      id: 404,
      title: t('notifications:errorOccured'),
      message: <>{t('notifications:offerNotAvailable')}</>,
      buttons: ['backToOfferlist']
    }
  ];

  let message = messages.find((item) => item.id === type);

  if (!message) {
    message = {
      id: 'unknown',
      title: t('notifications:unknownErrorOccured'),
      message: <>{t('notifications:backToOfferlist')}</>,
      buttons: ['backToOfferlist']
    };
  }

  return (
    <div className={classNames(styles.blockMessage, className)}>
      <h3 className={styles.blockMessage__title}>{message.title}</h3>
      <p className={styles.blockMessage__message}>{message.message}</p>

      {message.buttons.length > 0 && (
        <div className={styles.blockMessage__buttons}>
          {message.buttons.indexOf('clearFilters') !== -1 && (
            <Button variant="secondary" onClick={clearFilters}>
              <span>{t('0nc_reset_filters')}</span>
              <CarIcon icon="cross" />
            </Button>
          )}

          {message.buttons.indexOf('backToOfferlist') !== -1 && (
            <Button variant="secondary">
              <span>{t('back_to_all_offers')}</span>
              <CarIcon icon="arrow-left" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

BlockMessage.defaultProps = {
  type: '',
  className: ''
};

export default BlockMessage;
