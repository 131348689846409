import React, { useEffect, useState } from 'react';
import useOffersContext from 'providers/offers/use-offers-context';
import { DoorsFilter, PassengersFilter } from 'src/filters/types';
import Dropdown from 'components/forms/dropdown';
import { DropdownItem } from 'components/forms/types';
import useTranslation from 'providers/translations/use-translations';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

type Props = {
  label?: string;
  labelPax?: string;
  labelDoors?: string;
  disabled?: boolean;
};

const DEFAULT_ADULTS_QTY = 2;
export const POSSIBLE_ADULTS_QTY = [2, 3, 4, 5, 6];

const DoorsAndPassengersFilter: React.FC<Props> = ({ label, labelPax, labelDoors, disabled }) => {
  const { filters, removeFilter, updateFilter } = useOffersContext();
  const { t } = useTranslation();
  const [passengers, setPassengers] = useState(DEFAULT_ADULTS_QTY);
  const [doors, setDoors] = useState('');

  const passengerOptions = POSSIBLE_ADULTS_QTY.map((i) => ({
    value: i,
    label: `${i} ${t('and_more')}`
  }));

  const doorsOptions = ['2', '2/3', '3', '2/4', '4', '4/5', '5'].map((i) => ({
    value: i
  }));

  const passengersChange = ({ value }: DropdownItem) => {
    if (passengers === value) {
      return;
    }
    setPassengers(value);
    if (value === DEFAULT_ADULTS_QTY) {
      removeFilter('passengers');
    } else {
      updateFilter({
        type: 'passengers',
        value: value || ''
      });
    }
  };

  const doorsChange = ({ value }: DropdownItem) => {
    if (doors === value) {
      return;
    }
    setDoors(value);
    if (!value) {
      removeFilter('doors');
    } else {
      updateFilter({
        type: 'doors',
        value
      });
    }
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'passengers') as PassengersFilter;
    if (!filter) {
      setPassengers(DEFAULT_ADULTS_QTY);
    } else if (filter.value !== passengers) {
      setPassengers(filter.value);
    }
  }, [filters, passengers]);

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'doors') as DoorsFilter;
    if (!filter) {
      setDoors('');
    } else if (filter.value !== doors) {
      setDoors(filter.value);
    }
  }, [filters, doors]);

  return (
    <FilterBox title={label}>
      <Dropdown
        label={labelPax}
        options={passengerOptions}
        className={styles.filtersDropdown}
        classNamesContent={[styles.filtersDropdown__inside]}
        classNamesValueContainer={[styles.filtersDropdown__box]}
        value={passengerOptions.find((item) => item.value === passengers)}
        onChange={passengersChange}
        noEmptyOption
        disabled={disabled}
      />
      <Dropdown
        label={labelDoors}
        className={styles.filtersDropdown}
        classNamesContent={[styles.filtersDropdown__inside]}
        classNamesValueContainer={[styles.filtersDropdown__box]}
        options={doorsOptions}
        value={doorsOptions.find((item) => item.value === doors) || undefined}
        onChange={doorsChange}
        disabled={disabled}
      />
    </FilterBox>
  );
};

DoorsAndPassengersFilter.defaultProps = {
  label: undefined,
  labelPax: undefined,
  labelDoors: undefined,
  disabled: false
};

export default DoorsAndPassengersFilter;
