import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';
import Price from 'components/common/price';
import Equip from 'modules/offer/equip';

import { noImageUrl } from 'src/config';
import styles from './category-slider.module.scss';

interface Props {
  category: string;
  offer: CarOffer;
  price: number;
  currency: string;
  className?: string;
}

const CategorySliderItem: FC<Props> = ({ offer, price, category, currency, className }) => {
  const { t } = useTranslation();
  const [hasImage, setHasImage] = useState(true);

  return (
    <>
      {offer && (
        <div className={classNames(styles.categorySlider__item, className)}>
          <div className={classNames(styles.categorySlider__category)}>{t(category, category)}</div>
          <div className={styles.offerPicture}>
            <img
              className={styles.offerPicture__img}
              alt={offer.name}
              src={hasImage ? offer.image.replace('75x75', '140x100') : noImageUrl}
              width="140"
              height="100"
              onError={() => setHasImage(false)}
            />
          </div>
          <Equip offer={offer} className={styles.carIcon} limitTo="categorySlider" />
          <span className={classNames(styles.price, styles.categorySlider__price)}>
            {t('price_from')}
            <Price round className={styles.offerPrice} price={price} currency={currency} />
          </span>
        </div>
      )}
    </>
  );
};

CategorySliderItem.defaultProps = {
  className: ''
};

export default CategorySliderItem;
