import Dropdown from 'components/forms/dropdown';
import { DropdownItem } from 'components/forms/types';
import useTranslation from 'providers/translations/use-translations';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import React, { FC, memo } from 'react';

type Props = {
  age: number;
  minAge?: number;
  maxAge?: number;
  onChange: (age: number) => void;
  scrollMobileTop?: boolean;
};

const AgeSelect: FC<Props> = ({ age, minAge, maxAge, onChange, scrollMobileTop }) => {
  const { t } = useTranslation();
  const options: Array<DropdownItem> = [];
  const { isMobile } = useScreenDetect();

  const SAFE_RANGE_MIN = 26;
  const SAFE_RANGE_MAX = 65;

  for (let i = minAge; i <= maxAge; i += 1) {
    if (i < SAFE_RANGE_MIN || i > SAFE_RANGE_MAX) {
      options.push({
        value: i,
        label: `${i}`
      });
    } else if (i === 35) {
      options.push({
        value: 35,
        label: '26 - 65',
        class: 'doubled'
      });
    }
  }

  return (
    <Dropdown
      label={`${t('driver_age')}:`}
      value={options.find((item) =>
        age > SAFE_RANGE_MIN && age < SAFE_RANGE_MAX ? item.value === 35 : item.value === age
      )}
      classNames={['ageSelectDropdown']}
      options={options}
      noEmptyOption
      onChange={(item) => onChange(item.value)}
      alternativeList={isMobile || false}
      scrollMobileTop={scrollMobileTop}
      alternativeValign="top"
      horizontal
    />
  );
};

AgeSelect.defaultProps = {
  minAge: 18,
  maxAge: 99,
  scrollMobileTop: false
};

export default memo(AgeSelect);
