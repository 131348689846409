import PartnerLogo from 'components/common/partner-logo';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import React, { FC } from 'react';

import styles from '../stations-map.module.scss';
import { StationClusterData } from '../types';

interface Props {
  data: StationClusterData;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationClusterWindow: FC<Props> = (props) => {
  const { t } = useTranslation();
  const setFilter = () => {
    props.setStationFilter(props.data.stations.map((item) => item.id));
  };

  return (
    <div className={styles.stationDetails}>
      <ul className={styles.stationDetails__partners}>
        {props.data.partners.map((code) => (
          <PartnerLogo
            name={code}
            key={code}
            width={75}
            height={20}
            className={styles.stationDetails__logo}
          />
        ))}
      </ul>
      <Button onClick={setFilter}>{t('0nc_choose_this_station')}</Button>
    </div>
  );
};

StationClusterWindow.defaultProps = {
  setStationFilter: () => null
};

export default StationClusterWindow;
