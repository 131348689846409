import React, { useEffect, useRef, memo } from 'react';
import useState from 'react-usestateref';
import classNames from 'classnames';
import { get, clone, cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';

import { DayPicker } from 'react-day-picker';
import OverlayWrapper from 'components/common/overlay-wrapper';
import { useRouter } from 'next/router';
import useOnclickOutside from 'react-cool-onclickoutside';

import useNonInitialEffect from 'hooks/use-non-initial-effect';

import { DateRange } from 'types/date-range.type';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import { SlideUp } from 'components/common/animations';
import { hourDiff } from 'src/utils/date-time';
import { de, enGB, pl, ru, tr, es, it, cs, fr } from 'date-fns/locale';
import DatepickerInput from './datepicker-input';
import CurrentSelection from './current-selection';

import styles from './datepicker.module.scss';

type DatepickerProps = {
  range?: boolean;
  onChange?: (dates: DateRange) => void;
  value?: DateRange;
  minDays?: number;
  maxDays?: number;
  labels?: Array<string>;
};

const Datepicker: React.FC<DatepickerProps> = (props) => {
  const { locale } = useRouter();
  const locales = [de, enGB, pl, ru, tr, es, it, cs, fr];
  const containerEl = useRef(null);
  const { isMobile } = useScreenDetect();
  const [minDate] = useState(moment().add(props.minDays, 'days'));
  const [maxDate] = useState(moment().add(props.maxDays, 'days'));
  const [from, setFrom, fromRef] = useState(props.value.from);
  const [to, setTo, toRef] = useState(props.range ? props.value.to : undefined);
  const [innerTo, setInnerTo] = useState(props.range ? props.value.to : undefined);
  const [hover, setHover] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [mode, setMode] = useState<'from' | 'to'>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  /* ------------------------------------------------------------------------------ INTERFACE --- */

  const collapse = () => {
    setExpanded(false);
    setMode(null);
    setHover(false);
    setMobileOpen(false);
  };

  const goToSecondDate = (day: Moment) => {
    if (props.range) {
      setMode('to');
      if (day >= to) {
        const newDate = clone(props.value.to);
        newDate.date(day.date()).month(day.month()).year(day.year()).add(1, 'days');
        setTo(newDate);
        setInnerTo(newDate);
      }
    } else {
      collapse();
    }
  };

  /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

  // Collapse datepicker on click outside
  useOnclickOutside(
    () => {
      if (expanded) collapse();
    },
    { refs: [containerEl] }
  );

  // Handle start date input click
  const handleFromClick = () => {
    setMode('from');
    if (isMobile) setMobileOpen(true);
    setExpanded(true);
  };

  // Handle end date input click
  const handleToClick = () => {
    setMode('to');
    if (isMobile) setMobileOpen(true);
    setExpanded(true);
  };

  const handleMobileClose = () => {
    setExpanded(false);
    setMode(null);
    setHover(false);
    setMobileOpen(false);
  };

  // Change start or end of the selection
  const handleDayClick = (dayString, modifiers) => {
    const previosDate = clone(mode === 'from' ? props.value.from : props.value.to);
    const pickedDate = moment(dayString).hour(previosDate.hour()).minute(previosDate.minute());
    if (get(modifiers, 'disabled', false)) return;

    // Allow to set any pickup date
    if (mode === 'from') {
      setFrom(pickedDate);
      goToSecondDate(pickedDate);
      return;
    }

    // Update dropoff if it is later then pickup
    if (hourDiff(from, pickedDate) >= 0) {
      setTo(pickedDate);
      setInnerTo(pickedDate);
      collapse();
      return;
    }

    // Update dropoff and fix pickup to prevent reversed date range
    if (pickedDate.hour() < 12) pickedDate.hour(12);
    setFrom(cloneDeep(pickedDate).hour(10));
    setTo(pickedDate);
    setInnerTo(pickedDate);
    collapse();
  };

  // Live preview of the end date
  const handleDayMouseEnter = (dayString) => {
    const day = moment(dayString);
    if (mode === 'to') setInnerTo(day);
  };

  // Range mode changes
  useEffect(() => {
    if (props.range) {
      setFrom(props.value.from);
      setTo(props.value.to);
      setInnerTo(props.value.to);
    } else {
      setTo(undefined);
      setInnerTo(undefined);
    }
  }, [props.range]);

  // Emit event on from/to date change
  useNonInitialEffect(() => {
    props.onChange({ from, to });
  }, [from, to]);

  useNonInitialEffect(() => {
    if (!fromRef.current.isSame(props.value.from)) setFrom(props.value.from);
    if (!toRef.current.isSame(props.value.to)) {
      setTo(props.value.to);
      setInnerTo(props.value.to);
    }
  }, [props.value]);

  /* -------------------------------------------------------------------------------- HRENDER --- */

  return (
    <div
      ref={containerEl}
      className={classNames(styles.container, {
        [styles.rangeContainer]: props.range,
        [styles['container--highlight']]: hover || expanded
      })}
      style={{
        position: mobileOpen ? 'absolute' : 'unset'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {(!isMobile || (isMobile && !expanded)) && (
        <DatepickerInput
          label={props.labels ? props.labels[0] : undefined}
          date={from}
          focused={mode === 'from'}
          onClick={handleFromClick}
          icon="pickup"
        />
      )}

      {props.range && (!isMobile || (isMobile && !expanded)) && (
        <DatepickerInput
          label={props.labels ? props.labels[1] : undefined}
          date={to}
          focused={mode === 'to'}
          onClick={handleToClick}
          icon="droppoff"
        />
      )}

      <SlideUp open={expanded}>
        <div className={styles.pickerWrapper}>
          <OverlayWrapper
            classNameOuter={styles.datepickerWrapperOuter}
            className={styles.datepickerWrapper}
            classNameContent={styles.datepickerWrapperContent}
            onClose={handleMobileClose}
            closeBtn
            open={mobileOpen}
            inUse={isMobile}
            useClickOutside={false}
            valign="top"
            title={props.labels[2]}
            scrollMobileTop
          >
            <DayPicker
              mode="range"
              locale={locales.find((x) => x.code === locale.slice(0, 2))}
              numberOfMonths={isMobile ? 1 : 2}
              selected={{ from: from.toDate(), to: innerTo.toDate() }}
              defaultMonth={from.toDate()}
              onDayClick={handleDayClick}
              onDayMouseEnter={handleDayMouseEnter}
              disabled={[
                {
                  after: maxDate.toDate()
                },
                { before: minDate.toDate() }
              ]}
            />
            <CurrentSelection mode={mode} from={from} to={innerTo} />
          </OverlayWrapper>
        </div>
      </SlideUp>
    </div>
  );
};

Datepicker.defaultProps = {
  range: true,
  labels: [],
  value: {
    from: moment().add(7, 'days'),
    to: moment().add(8, 'days')
  },
  minDays: 1,
  maxDays: 360,
  onChange: () => null
};

export default memo(Datepicker);
