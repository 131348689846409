import React, { FC, memo, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import Dropdown from 'components/forms/dropdown';
import { DropdownItem } from 'components/forms/types';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useTranslation from 'providers/translations/use-translations';
import { twoDigits } from 'src/utils/string';

import { DateRange } from 'types/date-range.type';

import { Moment } from 'moment';
import styles from './searchform.module.scss';

type Props = {
  value: DateRange;
  onChange?: (val: DateRange) => void;
  sameDayCheck?: boolean;
  scrollMobileTop?: boolean;
};

const prepareHourItems = (startHour = 0, startMinutes = 0): Array<DropdownItem> => {
  const items: Array<DropdownItem> = [];

  for (let h = startHour; h < 24; h += 1) {
    if (h > startHour) {
      items.push({
        value: `${twoDigits(h)}:00`
      });
    }
    if (h > startHour || startMinutes === 0 || h === 23) {
      items.push(
        {
          value: `${twoDigits(h)}:15`
        },
        {
          value: `${twoDigits(h)}:30`
        },
        {
          value: `${twoDigits(h)}:45`
        }
      );
    }
  }
  return items;
};

const TimePicker: FC<Props> = (props) => {
  const [optionsPickup] = useState<Array<DropdownItem>>(prepareHourItems());
  const [optionsDropoff, setDropoffOptions] = useState<Array<DropdownItem>>(
    props.value.from.isSameOrAfter(props.value.to)
      ? prepareHourItems(props.value.from.hours(), props.value.from.minutes())
      : prepareHourItems()
  );
  const { t } = useTranslation();
  const hourFromDate = (date: Moment): string => date.format('HH:mm');
  const { isMobile } = useScreenDetect();

  const handleDayCheck = () => {
    if (props.sameDayCheck === true) {
      const datePickup = cloneDeep(props.value.from).startOf('day');
      const dateDropoff = cloneDeep(props.value.to).startOf('day');
      if (datePickup.diff(dateDropoff) >= 0) {
        if (props.value.from.isSameOrAfter(props.value.to)) {
          if (props.value.from.hour() < 23) {
            props.value.to.hour(props.value.from.hour() + 1).minute(props.value.from.minutes());
          } else {
            props.value.to.hour(props.value.from.hour()).minute(30);
          }
        }
        setDropoffOptions(prepareHourItems(props.value.from.hours(), props.value.from.minutes()));
      } else {
        setDropoffOptions(prepareHourItems());
      }
    }
  };

  const fromChanged = (item: DropdownItem) => {
    const [hours, minutes] = item.value.split(':');
    props.value.from.hour(hours).minute(minutes);
    props.onChange(props.value);
    handleDayCheck();
  };

  const toChanged = (item: DropdownItem) => {
    const [hours, minutes] = item.value.split(':');
    props.value.to.hour(hours).minute(minutes);
    props.onChange(props.value);
  };

  useEffect(() => {
    handleDayCheck();
  }, [props.value, props.value]);

  return (
    <>
      <Dropdown
        label={`${t('pickup_time')}:`}
        insideLabel={isMobile}
        value={optionsPickup.find((item) => item.value === hourFromDate(props.value.from))}
        options={optionsPickup}
        classNames={['pickupTimepickerWrapper']}
        onChange={fromChanged}
        alternativeList={isMobile || false}
        scrollMobileTop={props.scrollMobileTop}
        alternativeValign="top"
        classNamesContent={[styles.fullSize]}
        classNamesValueContainer={[styles.fullHeight, 'pickupTimepicker']}
        icon="clock"
        size="large"
        noEmptyOption
      />
      <Dropdown
        label={`${t('dropoff_time')}:`}
        insideLabel={isMobile}
        value={optionsDropoff.find((item) => item.value === hourFromDate(props.value.to))}
        options={optionsDropoff}
        classNames={['returnTimepickerWrapper']}
        onChange={toChanged}
        alternativeList={isMobile || false}
        scrollMobileTop={props.scrollMobileTop}
        alternativeValign="top"
        classNamesContent={[styles.fullSize]}
        classNamesValueContainer={[styles.fullHeight, 'returnTimepicker']}
        icon="clock"
        size="large"
        noEmptyOption
      />
    </>
  );
};

TimePicker.defaultProps = {
  onChange: () => null,
  sameDayCheck: false,
  scrollMobileTop: false
};

export default memo(TimePicker);
