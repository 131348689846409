import React, { FC, ReactNode, useState, Children } from 'react';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import CarIcon from 'components/common/car-icon';
import { SlideDown } from 'components/common/animations';
import styles from './filters.module.scss';

const MAX_LIMIT = 999;
interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  collapsable?: boolean;
  collapsed?: boolean;
  limit?: number;
}

const FilterBox: FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(!props.collapsed);
  const [limit, setLimit] = useState(props.limit || 0);
  const { t } = useTranslation();

  return (
    <div className={styles.filterBox}>
      <div className={styles.filterBox__heading}>
        <div className={styles.filterBox__heading__upper}>
          {props.title && <span className={styles.filterBox__title}>{props.title}</span>}
          {props.collapsable && (
            <Button
              variant="text"
              icon="chevron-down"
              style={{
                transform: expanded ? 'rotateX(180deg)' : undefined
              }}
              onClick={() => setExpanded(!expanded)}
            />
          )}
        </div>
        {props.subtitle && <p className={styles.filterBox__subtitle}>{props.subtitle}</p>}
      </div>

      <SlideDown open={expanded}>
        <div className={styles.filterBox__content}>
          {(!props.limit || Children.count(props.children) <= props.limit) && props.children}

          {props.limit && Children.count(props.children) > props.limit && (
            <>
              {Children.toArray(props.children)
                .slice(0, limit)
                .map((item) => item)}

              <Button
                variant="secondary"
                className={styles.filterBox__moreBtn}
                onClick={() => setLimit(MAX_LIMIT === limit ? props.limit : MAX_LIMIT)}
                noUnderline
              >
                <CarIcon
                  icon="chevron-down"
                  size="13px"
                  style={{
                    transform: limit === MAX_LIMIT ? 'rotateX(180deg)' : undefined
                  }}
                />
                <span>{limit !== MAX_LIMIT ? t('more') : t('less')}</span>
              </Button>
            </>
          )}
        </div>
      </SlideDown>
    </div>
  );
};
FilterBox.defaultProps = {
  title: undefined,
  subtitle: undefined,
  children: undefined,
  collapsable: false,
  collapsed: false,
  limit: undefined
};

export default FilterBox;
